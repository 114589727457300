import _ from "lodash";


const isDailyPeriod = (period?: string): boolean => {
    if (!period) {
        return false;
    }
    return (/^\d{4}-\d{2}-\d{2}$/.test(period)) || period === 'today' //daily
}


const isPeriodForMonthlyGroup = (period?: string): boolean => {
    return isYearlyPeriod(period) || period == '*';
}

const isYearlyPeriod = (period?: string): boolean => {
    if (!period) {
        return false;
    }
    return (/^\d{4}$/.test(period)) //yearly
}
const isSmartPeriod = (period?: string): boolean => {
    if (!period) {
        return false;
    }
    return _.includes(['last30days'], period) //smart 
}
const decideGroupBy = (period?: string): GroupBy => {
    return isPeriodForMonthlyGroup(period) ? 'month' :
        (isDailyPeriod(period) ? 'hour' : 'day');
}

export const ChartsHelper = {
    isDailyPeriod,
    isYearlyPeriod,
    isSmartPeriod,
    isPeriodForMonthlyGroup,
    decideGroupBy,
}

export type GroupBy = 'hour' | 'weekday' | 'day' | 'month';