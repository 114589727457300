import axios, { AxiosError } from 'axios';
import { MediaHelper } from '../../utils';
import { Media } from '../../../domain';
import _ from 'lodash';
import { Bucket, VimeoVideo } from './domain';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../apis';
import { EdErrorHandler, SwalDelete } from '../../widgets';

const FileDownload = require('js-file-download');


export async function downloadMediaItem(url: string, filename: string): Promise<void> {
  const { data } = await axios.get(url, {
    responseType: 'blob',
    transformRequest: [(data, headers) => { delete headers.get.Pragma; return data }]
  })
  FileDownload(data, filename);
}

export const fullPathUrl = (magazine: number, url: string): string => {
  const fullPath = MediaHelper.proxify(magazine, url);
  return `${fullPath}`;
}



const extractVimeoLinkFromEmbedHtml = (html: string): string => {
  if (!html) {
    return '';
  }
  const src = html.match(/src=".*?"/g);
  if (!src) {
    return '';
  }
  const link = src[0].replace('src="', '').replace('"', '');
  return link;
}

const resizeVimeoEmbedIframe = (html: string): string => {
  if (!html) {
    return '';
  }
  const link = html.replace(/width=".*?"/g, 'width="150"').replace(/height=".*?"/g, 'height="auto"');
  return link;
}


const vimeoMediaAsMedia = (vimeoMedia: VimeoVideo[]): Media[] => {
  return _.map(_.filter(vimeoMedia, (vm) => MediaHelper.isVimeoShowableStatus(vm.status)), (m) => {
    return {
      fullName: m.name,
      name: m.name,
      extension: 'vimeo',
      folder: '',
      url: extractVimeoLinkFromEmbedHtml(m.embed.html),
      resourcePath: m.id,
      lastModified: m.created_time,
      integration: {
        id: m.id,
        embed: resizeVimeoEmbedIframe(m.embed.html)
      }
    }
  })
}


export function isVimeoBucket(bucket: string) {
  return bucket === 'vimeo'
}

export const createDeleteDialog = async (med: Media): Promise<boolean> => {
  const { value } = await SwalDelete.fire({
    title: 'Are you sure?',
    text: `This action will delete media file ${med.name} from everywhere. So make sure it isn't used in articles.`,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    focusCancel: true,
  });
  return value;
}


export const useMagazineFeedBuckets = (magazine: number) => useQuery({
  queryKey: ['buckets', magazine],
  queryFn: async () => {
    try {
      const { data } = await API.ics.getMagazineFeedBuckets(magazine);
      return data;
    } catch (error) {
      EdErrorHandler(error, `getting magazine buckets`);
    }
  },
})


export const useBucketMedia = (magazine: number, bucket: string, part: 'public' | 'protected') => useQuery({
  queryKey: ['bucket_media', magazine, bucket, part],
  queryFn: async () => {
    try {
      const data = isVimeoBucket(bucket)
        ? await API.vimeo.getVideos(magazine).then(resp => vimeoMediaAsMedia(resp.data.data))
        : await API.ics.getIcsBucketPartMedia(magazine, bucket, part)
          .then(resp => resp.data)
          .catch((e) => {
            if ((e as AxiosError).isAxiosError && (e as AxiosError).response?.status === 404) {
              return Promise.resolve([])
            } else {
              throw e
            }
          });
      //small fix on folder
      return data.map(m => m.folder === '/' ? { ...m, folder: '' } : m)
    } catch (error) {
      EdErrorHandler(error, `getting media of bucket <${bucket} (${part})>`);
    }
  }
})


export const useDeleteMediaMutation = (magazine: number, bucket: string, part: 'public' | 'protected', media: Media[],) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (med: Media) => {
      try {
        if (MediaHelper.isVimeoExtension(med.extension)) {
          await API.vimeo.deleteVideo(magazine, med.resourcePath);
        } else {
          await API.ics.deleteBucketPartMedia(magazine, bucket, part, med.resourcePath);
        }
        return media.filter((m) => m.resourcePath !== med.resourcePath);
      } catch (error) {
        EdErrorHandler(error, `deleting media file`);
      }
    },
    // Notice the second argument is the variables object that the `mutate` function receives
    onSuccess: (media: Media[]) => {
      queryClient.setQueryData(['bucket_media', magazine, bucket, part], media)
    },
  })
}


export const useInvalidateBucketMedia = () => {
  const queryClient = useQueryClient()
  return () => {
    queryClient.invalidateQueries(['bucket_media'])
  }
}


export function pathJoin(...arr: string[]): string {
  return _.compact(arr).join('/')
}

export const getCmsBuckets = (magazine: number, feedId: number, allBuckets: Bucket[],): Bucket[] => {
  const cmsBuckets = _.filter(allBuckets, (b) => {
    return _.endsWith(b.key, `feed-${feedId}`) || b.key === `${magazine}_cms`;
  })
  //ensure that default cms bucket is last
  return cmsBuckets.sort((a, b) => _.endsWith(a.key, '_cms') ? 1 : -1)
}