import React, { Component, Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Row, Col, Input, Label } from 'reactstrap';

import { StatsFilter } from '../../domain';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../api';

import './css/Charts.min.css';
import { I18n, Translate } from '../translations';
import { LanguageContextConsumer } from '../LanguageContext';

export interface Props {
  chart: string,
  magazine: number,
  statsFilter: StatsFilter,
  title: string,
  gradientId?: string,
  groupBy?: string,
  yAxisType?: string,
  secondaryChart?: string,
  valueName?: string,
  height?: number,
  secondaryValueName?: string,
  data?: any[],
  secondaryData?: any[]
}

export interface State {
  data?: any[],
  secondaryChart?: any[]

}


export class DashBarChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { data, secondaryData } = this.props;

    if (!data) {
      Api.loadChartData(this.props.chart, this.props.statsFilter, this.props.magazine.toString()).then((resp) => {
        if (!resp.data || resp.data.error || resp.data.data === '!') {
          console.log('Error in getting chart data');
          this.setState({ data: [] });
        } else {
          this.setState({ data: this.sortData(resp.data.data) });
        }
      })
        .catch((error) => {
          console.log('An error occured:' + error.message)
        })
      this.loadSecondaryChartData();
    } else {
      this.setState({ data: data, secondaryChart: secondaryData });
    }

  }
  loadSecondaryChartData() {
    const { secondaryChart } = this.props;
    if (!secondaryChart) {
      return;
    }
    Api.loadChartData(secondaryChart, this.props.statsFilter, this.props.magazine.toString()).then((resp) => {
      if (!resp.data || resp.data.error || resp.data.data === '!') {
        console.log('Error in getting chart data');
        this.setState({ secondaryChart: [] });
      } else {
        this.setState({ secondaryChart: this.sortData(resp.data.data) });
      }
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      })

  }
  sortData(data: any[]) {
    switch (this.props.groupBy) {
      case 'weekday':
        if (!data[0] || data[0].id !== 1) {
          return data;
        }
        const sunday = _.pullAt(data, [0]);
        data = _.concat(data, sunday);
        return data;
      default:
        return data;
    }
  }
  xTickFormatter(t: any) {
    switch (this.props.groupBy) {
      case 'hour':
        return moment().startOf('year').hours(_.toNumber(t)).format('HH:00');
      case 'weekday':
        return moment().startOf('year').day(_.toNumber(t) - 1).format('dddd');
      case 'day':
        return moment().startOf('year').date(_.toNumber(t)).format('Do');
      case 'visits':
        // return t + ((_.toNumber(t) == 10) ? '+' : '') + ' visit' + ((_.toNumber(t) != 1) ? 's' : '');
        return `${t}${_.toNumber(t) == 10 ? '+' : ''} visit${_.toNumber(t) !== 1 ? 's' : ''} ${!_.startsWith(this.props.valueName, 'avg_users_per_') ? '' : `per ${_.endsWith(this.props.valueName, 'day') ? 'day' : 'month'}`}`
      default:
        return t;
    }
  }
  yTickFormatter(t: any) {
    switch (this.props.yAxisType) {
      case 'time':
        return this.toTime(t);
      default:
        return (t > 1000) ? (t / 1000) + ' K' : t;
    }
  }
  tooltipFormatter(t: any) {
    switch (this.props.yAxisType) {
      case 'time':
        return this.toTime(t);
      case 'avg':
        return t.toFixed(2);
      default:
        return t;
    }
  }
  toTime(secs: number): string {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + (seconds ? (seconds + 's') : '');
  }
  prepareChartData() {
    const { data, secondaryChart } = this.state;
    if (!data) {
      return [];
    }
    if (!secondaryChart) {
      return data;
    }
    return data.map((d) => {
      const secondaryChartObj = _.find(secondaryChart, ['id', d.id]);

      return {
        ...d,
        secondaryValue: secondaryChartObj ? secondaryChartObj.value : 0
      }
    });
  }
  // tooltipLabelFormatter(name: any){
  //   console.log(value);
  //   console.log(name);
  //   console.log(props);

  // }
  render() {
    const { data } = this.state;
    const chartData = this.prepareChartData();
    const { valueName, secondaryValueName, height } = this.props;
    const fill = (this.props.gradientId == 'secondary') ? '#009BC2' : '#002e67';
    const stroke = (this.props.gradientId == 'secondary') ? '#009BC2' : '#002e67';
    const loader = <div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div>
    return (
      <div className="Charts_articleViews Charts_topLevelContainer">
        <div className="chart-header"><I18n t={this.props.title} n='Charts' /></div>
        <div className="chart-body">
          <LanguageContextConsumer>
            {languageContext => {
              if (!languageContext) {
                return null;
              }
              const language = languageContext.language;
              const tr = Translate(language, 'Metrics');
              return (
                <ResponsiveContainer height={height ? height : 280}>
                  {data === undefined ?
                    loader :
                    <BarChart data={chartData}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <CartesianGrid vertical={false} />
                      <XAxis dataKey="id" tickFormatter={(t) => this.xTickFormatter(t)} />
                      <YAxis tickFormatter={(t) => this.yTickFormatter(t)} allowDecimals={false} />
                      <Tooltip labelFormatter={this.xTickFormatter.bind(this)} formatter={this.tooltipFormatter.bind(this)} />
                      <Legend />
                      <Bar dataKey="value" name={tr(valueName || '')} stroke={stroke} fill={fill} animationDuration={500} />
                      {this.props.secondaryChart ?
                        <Bar dataKey="secondaryValue" name={tr(secondaryValueName || '')} stroke={(this.props.gradientId != 'secondary') ? '#009BC2' : '#002e67'} fill={(this.props.gradientId != 'secondary') ? '#009BC2' : '#002e67'} animationDuration={500} />
                        : ''
                      }
                    </BarChart>
                  }
                </ResponsiveContainer>
              )
            }}
          </LanguageContextConsumer>
        </div>
      </div>
    );
  }
}
