const Default = {
    unique_users: 'Unique Users',
    article_views: 'Article Views',
    avg_value: 'Avg value',
    view_more: 'View more',
    partOfAllAudiences: 'User must be part of all selected audiences',
    partOfOneOfAudiences: 'User must be part of at least one of the selected audiences',

}

const Modules = {
    overview: 'Overview',
    analytics: 'Analytics',
    content: 'Content',
    agenda: 'Agenda',
    engage: 'Engage',
    report: 'Report',
    narrowcasting: 'Narrow casting',
    devices: 'Devices',
    slideshows: 'Slideshows',
    settings: 'Settings',
    advanced: 'Advanced',
    pushNotifications: 'Push notifications',
    forms: 'Forms',
    newsletters: 'Newsletters',
    alerts: 'Alerts',
    channels: 'Channels',
    sources: 'Sources',
    articles: 'Articles',
    comments: 'Comments',
    platform: 'Platform',
    mediaManager: 'Media Manager',
    reportedContent: 'Reported Content',
    widgets: 'Widgets',
    monitoring: 'Monitoring',
    chatGroups: 'Chat Groups',
    categories: 'Categories',
    audiences: 'Audiences',
    notificationCenter: 'Notification Center',
    sharepoint: 'Sharepoint Subscriptions'
}

const Months = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
}

const FilterBar = {
    period: 'period',
    audience: 'audience',
    all_employees: 'Everyone',
    since_app_start: 'Since App start',
    last30days: 'Last 30 days',
    this: 'This',
    last: 'Last',
    month: 'Month',
    week: 'Week',
    year: 'Year',
    day: 'Day',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisYear: 'This year',
    lastYear: 'Last year',
    yesterday: 'Yesterday',
    today: 'Today',
};

const Charts = {
    unique_users_and_visits_per_day: 'Unique Users and Visits per day',
    unique_users_and_visits_per_weekday: 'Unique Users and Visits per day of week',
    unique_users_and_visits_per_month: 'Unique Users and Visits per month',
    unique_users_and_visits_per_hour: 'Unique Users and Visits per hour of day',
    frequency_of_visits_per_user: 'Frequency of Visits per User',
    avg_articles_per_user_per_day: 'Avg. Articles per User per day',
    avg_articles_per_user_per_weekday: 'Avg. Articles per User per day of week',
    avg_articles_per_user_per_month: 'Avg. Articles per User per month',
    avg_articles_per_user_per_hour: 'Avg. Articles per User per hour of day',
    avg_visit_duration_time_reading_per_day: 'Avg. visit duration and Avg. time reading an article per day',
    avg_visit_duration_time_reading_per_weekday: 'Avg. visit duration and Avg. time reading an article per day of week',
    avg_visit_duration_time_reading_per_month: 'Avg. visit duration and Avg. time reading an article per month',
    avg_visit_duration_time_reading_per_hour: 'Avg. visit duration and Avg. time reading an article per hour of day',
    views_and_reads_per_day: 'Views and Reads per day',
    views_and_reads_per_weekday: 'Views and Reads per day of week',
    views_and_reads_per_month: 'Views and Reads per month',
    views_and_reads_per_hour: 'Views and Reads per hour of day',
}

const Metrics = {
    unique_users: 'Unique Users',
    visits: 'Visits',
    users: 'Users',
    article_views: 'Article Views',
    avg_articles_per_user: 'Avg. Articles per User',
    avg_visit_duration: 'Avg. Visit duration',
    avg_time_reading_an_article: 'Avg. Time reading an article',
    avg_users_per_month: 'Avg. Users',
    avg_users_per_day: 'Avg. Users',
    active_publications: 'Active Publications',
    publications: 'Publications',
    active_forms: 'Active Forms',
    pushNotifications: 'Push Notifications',
    newsletters: 'Newsletters',
    reach: 'reach',
    avg_visits_per_user: 'Avg. Visits per User',
    channel_views: 'Tile Selections',
    likes: 'Likes',
    avg_reading_time: 'Avg. Reading Time',
    top_articles: 'Top Articles',
    top_channels: 'Top Tiles',
    shares: 'Shares',
    comments: 'Comments',
    last_viewed: 'Last viewed',


}

const GroupFilter = {
    groupBy: 'Group By',
    month: 'Month',
    days: 'Days',
    day: 'Day',
    weekdays: 'Days of Week',
    hour: 'Hour of Day',
}

const Tables = {
    entries: 'entries',
    go_to_page: 'Go to page',
    comments: 'Comments in the selected period',
    last_viewed: 'Last viewed (shown in your local time zone)',
    created: 'Created on (shown in your local time zone)',
}

const ArticleDetails = {
    article_details: 'Article Details',
    could_not_load_article_content: 'Could not load article content',
    comments: 'Comments',
    information: 'Information',
    id: 'ID',
    published: 'Published',
    last_updated: 'Last Updated',
    status: 'Status',
    channels: 'Channels',
    sources: 'Sources',
    visible: 'Visible',
    active: 'Active',
    inactive: 'Inactive',
    delete_selected_comments: 'delete selected comments',
    comments_section_info: 'All comments on this article are listed below as filters do not apply, while shown time here reflects to your local time',
    info: 'Information',
    informations_section_info: 'Shown time here reflects to your local time',
    universalLink: 'Universal Link'
}

const PushNotifications = {
    scheduledFor: 'Scheduled For',
    status: 'Status',
    devices: 'Devices',
    overview: 'Overview',
    sent: 'Sent',
    send: 'Send',
    scheduled: 'Scheduled',
    cancelled: 'Cancelled',
    message: 'Message',
    charactersLeft: 'characters left',
    writeYourMessageHere: 'Write your message here',
    article: 'Article',
    url: 'URL',
    orSelectAnotherArticle: 'Or select another',
    orSelectArticle: 'Or select article',
    schedule: 'Schedule',
    targeting: 'Targeting',
    profiles: 'Profiles',
    sendImmediately: 'Send immediately',
    selectAudience: 'Select Audience',
    all_employees: 'Everyone',
    pushNotificationsNotFound: 'Push notification not found',
    pushNotificationFrom: 'Push Notification from',
    immediately: 'Immediately',
    cancelScheduling: 'cancel scheduling',
    targetedAudiences: 'Targeted Audiences',
    target: 'Target',
    noInfo: 'No info',
    created: 'Created',
    results: 'Results',
    deliveredTo: 'Delivered to',
    preview: 'Preview',
    noPreview: 'No preview',
    datesOnLocal: 'Time is in your local time',

}

const ArticleSelector = {
    selectArticle: 'Select article',
    channel: 'Channel',
    channels: 'Channel(s)',
    lastModifiedInSourrce: 'Last modified in source',
    allChannels: 'All channels',
    previewArticle: 'Preview article',
    articleQuickView: 'Article Quick View',
    couldNotLoadArticleContent: 'Could not load article content',
}

const SideBar = {
    loggedAs: 'Logged as',
    userSettings: 'User settings',
    logout: 'Logout',
}

const Report = {
    report: 'Report',
    name: 'Name',
    period: 'Period',
    audience: 'Audience',
    scheduled: 'Scheduled',
    archived: 'Archived',
    schedule: 'Schedule',
    generate: 'Generate',
    when: 'When',
    delete: 'Delete',
    download: 'Download',
    monthlyLabel: 'Every month',
    weeklyLabel: 'Every week',
    dailyLabel: 'Every day',
    quarterlyLabel: 'Every quarter',
    mailTo: 'Email to',
    addEmailList: 'Add email list',
    all_employees: 'Everyone',
    selectAudience: 'Select Audience',
    downloadReport: 'Download Report',
    generateReport: 'Generate Report',
    generatedReport: 'Generated Report',
    scheduleReport: 'Schedule Report',
    scheduledReport: 'Scheduled Report',
    automaticPeriodicMonthlyReports: 'Automatic Periodic Monthly Reports',
    automaticPeriodicWeeklyReports: 'Automatic Periodic Weekly Reports',
    recipientEmails: 'Recipient Emails',
    add_email: 'Add Email',
    monthlyReports: 'Monthly Reports',
    weeklyReports: 'Weekly Reports',
    scheduledReports: 'Scheduled Reports',
    reportsArchive: 'Archive',
    activeMonthly: 'Activated', //'Active Monthly Report',
    activeWeekly: 'Active Weekly Report',
    lastSent: 'Last Sent',
    nextOn: 'Next On',
    sendTest: 'Send test report',
    created: 'Created',
    customReports: 'Custom Reports'

}

const Channels = {
    channelType: 'Channel Type',
    audiences: 'Targeted Audiences',
    sources: 'Sources',
    totalSources: 'Total Sources',
    all_employees: 'Everyone',
    createTopLevelChannel: 'Create top level channel',
    createChildChannel: 'Create child channel',
    delete: 'Delete',
    createChannel: 'Create Channel',
    updateChannel: 'Update Channel',
    childOf: 'Child of',
    createNewSource: 'Create new source',
    active: 'Active',
    inactive: 'Inactive',
    sourceType: 'Source Type',
    channels: 'Channels',
    articles: 'Articles',
    tags: 'Tags',
    status: 'Status',
    stopSorting: 'Exit sorting mode',
    moveBefore: 'Move before',
    moveAfter: 'Move after',
    moveInside: 'Move inside',
    move: 'Move',

    allEmployees: 'Everyone',
    partOfAllAudiences: 'User must be part of all selected audiences',
    parents: 'Parents',
    children: 'Children',
    childValidationSelf: 'You cannot select a channel to be a child of itself',
    childValidationAncestor: 'You cannot select an ancestor channel as a child',
    childValidationSingleParent: 'You cannot deselect channels that have no other parent',
    parentValidationSelf: 'You cannot select a channel to be a parent of itself',
    parentValidationDescendant: 'You cannot select a descendant channel as a parent',
    deleteValidation: 'You cannot delete channels that have children',

}

const Sources = {
    create: 'Create',
    update: 'Update',
    source: 'Source',
    articles: 'Articles',
    inChannels: 'In channels',
    sourceType: 'Source Type',
    selectSrcType: 'Select type of source',
    selectSource: 'Select source',
    selectFeed: 'Select feed',
    sourceNotFound: 'This source had not been found. Please check provided source id.'

}

const Forms = {
    poll: 'Poll',
    pollDescr: 'A single question with multiple answers. A user can only select one answer.',
    survey: 'Survey',
    surveyDescr: 'An extensive form where you have all sort of questions.',
    quiz: 'Quiz',
    quizDescr: 'Multiple quesitions of different kinds where an answer can be wrong or right.',
    selectFormType: 'Select type of form',
    createNewForm: 'Create new form',
    formType: 'Form type',
    formResults: 'Total answers',
    created: 'Created',
    status: 'Status',
    delete: 'Delete',
    duplicate: 'Duplicate',
    viewResults: 'View results',
    create: 'Create',
    update: 'Update',
    formNotFound: 'Form not found',
    formBuilder: 'Form Builder',
    saveAsDraft: 'Save as draft',
    saveAndPublish: 'Save and publish',
    csvOverall: 'CSV overall',
    csvDetailed: 'CSV detailed',
    download: 'Download',
    draft: 'Draft',
    published: 'Published',
    finished: 'Finished',
    textPage: 'Text Page',
    questionPage: 'Question',
    optInPage: 'Opt In',
    resultsPage: 'Results',
    addToAnotherChannel: 'Add to another channel',
    removeFromThisChannel: 'Remove from this channel',

}

const TargetingWidget = {
    targeting: 'Targeting',
    profiles: 'Profiles',
    devices: 'Devices',
    selectAudience: 'Select Audience',
    selectAudiences: 'Select Audiences',
    all_employees: 'Everyone',


}

const Cms = {
    cms: 'CMS',
    author: 'Author',
    targetedUser: 'Targeted User',
    source: 'Source',
    update: 'Update',
    create: 'Create',
    publish: 'Publish',
    article: 'Article',
    createNewArticle: 'Create new article',
    createNewAgendaItem: 'Create new agenda item',
    featuredImage: 'Featured image',
    publishingDate: 'Publishing date',
    creationDate: 'Creation date',
    categories: 'Categories',
    tags: 'Tags',
    visible: 'Visible',
    hidden: "Hidden",
}
const Agenda = {
    agenda: 'Agenda',
    author: 'Author',
    targetedUser: 'Targeted User',
    update: 'Update',
    create: 'Create',
    publish: 'Publish',
    event: 'Event',
    createNewEvent: 'Create new agenda item',
    editEvent: 'Edit event',
    publishingDate: 'Publishing date',
    creationDate: 'Creation date',
    categories: 'Categories',
    visible: 'Visible',
    hidden: "Hidden",
    title: 'Title',
    titlePlaceholder: 'Add event\'s title',
    speakers: 'Speakers',
    speakerPlaceholder: 'Add event\'s speakers',
    location: 'Location',
    locationPlaceholder: 'Add event\'s location',
    start: 'Start date (in Location)',
    // startPlaceholder: 'Select start date and time',
    end: 'End date',
    selectDate: 'Select date and time',
    selectOnlyDate: 'Select date',
    selectDateAndTime: 'Select date and time',
    timezone: 'Timezone',
    // locationPlaceholder: 'Add event\'s location',
    displayTime: 'Display time',
    // locationPlaceholder: 'Add event\'s location',
    description: 'Description',
    user: 'User',
    allDay: 'All day'
}

const MediaManagerSelector = {
    selectMedia: 'Select Media',
    defaultMagazineBuckets: 'Default Magazine Buckets',
    magazineFeedsBuckets: 'Magazine Feeds Buckets',
    otherBuckets: 'Other Buckets',
    defaultBucket: 'Magazine Default bucket',
    defaultSocialBucket: 'Magazine Social bucket',
    defaultNcBucket: 'Magazine Narrowcasting bucket',
    defaultCmsBucket: 'Magazine CMS bucket',
    defaultVimeoBucket: 'Magazine Vimeo folder',
    defaultWebappBucket: 'Magazine Webapp bucket',
    uploadNew: 'Upload new',
    uploadNewImages: 'Upload new images',
    uploadNewVideos: 'Upload new videos',
    uploadNewFiles: 'Upload new files',
    uploadNewMedia: 'Upload new media',
    images: 'Images',
    audios: 'Audios',
    videos: 'Videos',
    files: 'Files',

}

const Monitoring = {
    advanced: 'Advanced',
    detailed: 'Detailed',
}

const QrModal = {
    scanToVisit: 'Scan to visit the',
    clickLink: 'or click the link below',
    article: 'article',
    form: 'form',
}

const WidgetManager = {
    selectWidget: 'Select Widget Instance',
    createNewWidget: 'Create a new Instance',
    selectDate: 'Select Date',
    showData: 'Show Data',
    viewRegisteredUsers: 'View registered users',
    viewConfirmedUsers: 'View confirmed users',
    widgetType: 'Widget Type',
    extraInfo: 'Extra Info',
    startDate: 'Start Date',
    endDate: 'End Date',
    spots: 'Spots',
    manageWidgets: 'Manage Widgets',
    eventRegistration: 'Event registration',
    confirmationButton: 'Confirmation button',
}
const FormSelector = {
    selectForm: 'Select Form',
    select: 'Select',
}

const DetailModals = {
    save: 'Save',
    create: 'Create',
}



//
// ─── EXPORT ALL NAME SPACES ─────────────────────────────────────────────────────
//   
export default {
    Default,
    Modules,
    Months,
    FilterBar,
    Charts,
    GroupFilter,
    Metrics,
    Tables,
    ArticleDetails,
    PushNotifications,
    ArticleSelector,
    SideBar,
    Report,
    Channels,
    Sources,
    Forms,
    TargetingWidget,
    Cms,
    MediaManagerSelector,
    Monitoring,
    QrModal,
    Agenda,
    WidgetManager,
    FormSelector,
    DetailModals,
}




