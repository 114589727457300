import React, { Component } from 'react';
import { Alert, Input } from 'reactstrap';
import { StatsFilter, Audience } from '../../../domain';
import * as _ from 'lodash';
import './css/FilterBar.min.css';
import { Translations } from '../../translations';
import "react-datepicker3/dist/react-datepicker.css";
import { ActivePeriodSelector } from '../ActivePeriodSlector/ActivePeriodSelector';

export interface Props {
  magazine: string,
  audiences: Audience[],
  globalAudience: boolean,
  statsFilter: StatsFilter,
  periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
}

export class FilterBar extends Component<Props> {
  renderAudienceFilter() {
    const { audienceFilterHandler, statsFilter, globalAudience, audiences } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <Input type="select" value={statsFilter.audience} onChange={(e) => { audienceFilterHandler(parseInt(e.target.value)) }} style={{ display: 'inline', width: '180px', marginLeft: '10px' }}>
        {globalAudience ?
          <option value="1">{tr('all_employees')}</option>
          : ''
        }
        {audiences.map((a) => {
          if (_.toNumber(a.id) != 1 && !a.excludeFromAnalytics) {
            return <option key={a.id} value={a.id}>{a.label}</option>
          }
        })}
        {this.renderInvalidAudienceFilter()}
      </Input>
    )
  }
  calcAudiences = () => {
    const { audiences, globalAudience } = this.props;
    const audiencesIds: number[] = [];
    if (globalAudience) {
      audiencesIds.push(1);
    }
    audiences.map((a) => {
      if (a.id && a.id != 1 && !a.excludeFromAnalytics) {
        audiencesIds.push(a.id);
      }
    })

    return audiencesIds;
  }
  renderInvalidAudienceFilter = () => {
    const audiencesIds = this.calcAudiences();
    const { audience } = this.props.statsFilter;
    if (!_.includes(audiencesIds, _.toNumber(audience))) {
      return (<option key={audience} value={audience}>Invalid Audience ({audience})</option>)
    }
    return null;
  }
  render() {
    const { magazine, periodFilterHandler, audienceFilterHandler, statsFilter, globalAudience } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <div id="ArticleDetailsFilterBar" style={{ alignItems: 'center' }}>
        {Translations.EnableTranslations(Translations)}
        {/* //TODO: Remove this when we integrate today analytics to the aggregation periods */}
        {statsFilter.period === 'today' &&
          <Alert color={'warning'} style={{ fontSize: '12px', marginBottom: '0px', padding: '3px 1.25rem' }}>
            <i className="fa fa-warning" style={{ marginRight: '4px' }}></i>
            The analytics for today are not included when another time period than 'today' is selected
          </Alert>
        }
        <div className="separator"></div>
        <div className="filterWrapper">
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase' }}>{tr('period')}:</span>
          <ActivePeriodSelector {...{ magazine, periodFilterHandler, statsFilter, toggleCustomClass: 'smallSelect', todayEnabled: true }} />
        </div>

        <div className="filterWrapper">
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase', marginLeft: '10px' }}>{tr('audience')}:</span>
          {this.renderAudienceFilter()}
        </div>

      </div>
    );
  }
}

export default FilterBar;
