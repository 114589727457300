import React, { Component, Fragment } from 'react';
import { FilterBarWithRouter as FilterBar } from './FilterBar';
import EngagementPromotersStats from './EngagementPromotersStats';
import TabbedChartsBox from './TabbedChartsBox';
import TopArticlesStats from './TopArticlesStats';
import { TopChannels } from './TopChannels';
import { StatsFilter, Profile } from '../../../domain';
import { Row, Col } from 'reactstrap';
import * as _ from 'lodash';
import * as ph from '../../utils/routerHelper';
import { withRouter, RouteComponentProps, Redirect } from 'react-router'
import { ArticleDetails } from '../../components';

import { FilterContextConsumer } from '../../FilterContext';

import './css/Overview.min.css';

export interface Props {
  magazine: string
  profile: Profile,
}

export class Overview extends Component<RouteComponentProps & Props> {
  constructor(props: RouteComponentProps & Props) {
    super(props);
  }
  clickArticleHandler(article: number) {
    ph.setQueryParams(this.props.history, { article });
  }
  closeArticleDetailsHandler() {
    ph.setQueryParams(this.props.history, { article: undefined });
  }
  render() {
    const { magazine, profile } = this.props;

    // TODO: remove this redirect when today is enabled for overview page
    // const period = ph.getQueryParam(this.props.location, 'p');
    // const audience = ph.getQueryParamNumber(this.props.location, 'a');
    // if (period === 'today') {
    //   return <Redirect to={`overview?p=last30days&a=${audience}`} />
    // }
    // const statsFilter: StatsFilter = {period,audience}; 
    const shownArticle = ph.getQueryParamNumber(this.props.location, 'article');
    return (
      <FilterContextConsumer>
        {filterContext => {
          if (!filterContext) {
            return ('')
          }
          const statsFilter: StatsFilter = filterContext.statsFilter;
          return (
            <Fragment>
              {shownArticle ?
                <ArticleDetails magazine={magazine} statsFilter={statsFilter} articleId={shownArticle} closeHandler={this.closeArticleDetailsHandler.bind(this)} profile={profile} />
                :
                ''
              }
              <div id="Overview" /*style={{ display: shownArticle ? 'none' : 'block' }}*/ className={shownArticle ? 'limitedHeight' : ''}>
                <FilterBar magazine={magazine} periodFilterHandler={filterContext.periodFilterHandler} audienceFilterHandler={filterContext.audienceFilterHandler} statsFilter={statsFilter} audiences={profile.audiences ? profile.audiences : []} globalAudience={profile.globalAudience} todayEnabled={true} />
                <Row>
                  <Col md={12} xl={4}>
                    <EngagementPromotersStats key={JSON.stringify(statsFilter)} magazine={magazine} statsFilter={statsFilter} profile={profile} />
                  </Col>
                  <Col md={12} xl={8}>
                    <TabbedChartsBox key={JSON.stringify(statsFilter)} magazine={magazine} statsFilter={statsFilter} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TopArticlesStats key={JSON.stringify(statsFilter)} magazine={magazine} profile={profile} statsFilter={statsFilter} clickArticleHandler={this.clickArticleHandler.bind(this)} />
                  </Col>
                  <Col md={6}>
                    <TopChannels key={JSON.stringify(statsFilter)} magazine={_.toNumber(magazine)} statsFilter={statsFilter} />
                  </Col>
                </Row>
                {/* <Row>
              <Col md={12}>
                <ActiveFormsStats  key={JSON.stringify(statsFilter)} magazine={magazine} statsFilter={statsFilter}/>
              </Col>
            </Row> */}
              </div>
            </Fragment>
          )
        }}
      </FilterContextConsumer>

    )
  }
}
export const OverviewWithRouter = withRouter(Overview);
export default Overview;
