import React, { Component, Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LineChart, Line, ReferenceLine } from 'recharts';
import { Row, Col, Input, Label } from 'reactstrap';

import { StatsFilter } from '../../domain';
import { fillMissingMonthDates, fillMissingWeekDates, fillMissingDates, fillMissingMonths } from '../utils/chartHelper';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../api';

import './css/Charts.min.css';
import { I18n, Translate } from '../translations';
import { LanguageContextConsumer } from '../LanguageContext';
import { ChartsHelper } from '../utils/ChartsHelper';
import { DateHelper } from '../utils';

export interface Props {
  chart: string,
  magazine: number,
  statsFilter: StatsFilter,
  title: string,
  gradientId?: string,
  groupBy?: string,
  yAxisType?: string,
  hideDot?: boolean,
  disableAnimation?: boolean,
  avgValue?: number,
  secondaryChart?: string,
  height?: number,
  valueName?: string,
  secondaryValueName?: string,
  fillMissingValues?: string,
  data?: any[],
  secondaryData?: any[]
}

export interface State {
  data?: any[],
  secondaryChart?: any[]
}


export class DashLineChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { data, secondaryData } = this.props;
    if (!data) {
      Api.loadChartData(this.props.chart, this.props.statsFilter, this.props.magazine.toString()).then((resp) => {
        if (!resp.data || resp.data.error || resp.data.data === '!') {
          console.log('Error in getting chart data');
          this.setState({ data: [] });
        } else {
          this.setState({ data: this.sortData(resp.data.data) });
        }
      })
        .catch((error) => {
          console.log('An error occured:' + error.message)
        })
      this.loadSecondaryChartData();
    } else {
      this.setState({ data: data, secondaryChart: secondaryData });
    }


  }
  loadSecondaryChartData() {
    const { secondaryChart } = this.props;
    if (!secondaryChart) {
      return;
    }
    Api.loadChartData(secondaryChart, this.props.statsFilter, this.props.magazine.toString()).then((resp) => {
      if (!resp.data || resp.data.error || resp.data.data === '!') {
        console.log('Error in getting chart data');
        this.setState({ secondaryChart: [] });
      } else {
        this.setState({ secondaryChart: this.sortData(resp.data.data) });
      }
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      })

  }
  sortData(data: any[]) {
    switch (this.props.groupBy) {
      case 'weekday':
        if (!data[0] || data[0].id !== 1) {
          return data;
        }
        const sunday = _.pullAt(data, [0]);
        data = _.concat(data, sunday);
        return data;
      default:
        return data;
    }
  }
  xTickFormatter(t: any) {
    switch (this.props.groupBy) {
      case 'hour':
        return moment().startOf('year').hours(_.toNumber(t)).format('HH');
      case 'weekday':
        return moment().startOf('year').day(_.toNumber(t) - 1).format('dddd');
      case 'day':
        const d = _.isNumber(t) ? moment(`${this.props.statsFilter.period}-${t}`, 'YYYY-MM-D').format('YYYY-MM-DD') : moment(t, 'YYYY-MM-DD').format('YYYY-MM-DD');
        return DateHelper.dateToLocaleString(d);
      case 'month':
        return moment(t, 'YYYY-MM').format('MM/YYYY');
      case 'visits':
        return t + ((_.toNumber(t) == 10) ? '+' : '') + ' visit' + ((_.toNumber(t) != 1) ? 's' : '');
      default:
        return t;
    }
  }
  yTickFormatter(t: any) {
    switch (this.props.yAxisType) {
      case 'time':
        return this.toTime(t);
      default:
        return (t > 1000) ? (t / 1000) + ' K' : t;
    }
  }
  tooltipFormatter(t: any) {
    switch (this.props.yAxisType) {
      case 'time':
        return this.toTime(t);
      case 'avg':
        return t.toFixed(2);
      default:
        return t;
    }
  }
  toTime(secs: number): string {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + (seconds ? (seconds + 's') : '');
  }
  fillDataWithMissingDates(data: any) {
    if (!this.props.statsFilter.period) {
      return fillMissingMonthDates(data, '');
    }
    if (this.props.statsFilter.period == 'last30days') {
      return fillMissingDates(data, this.props.statsFilter.period ? this.props.statsFilter.period : '');
    }
    if (/^\d{4}-W\d\d?$/.test(this.props.statsFilter.period)) { //weekly
      return fillMissingWeekDates(data, this.props.statsFilter.period ? this.props.statsFilter.period : '');
    }
    if (ChartsHelper.isPeriodForMonthlyGroup(this.props.statsFilter.period)) {
      return fillMissingMonths(data, this.props.statsFilter.period ? this.props.statsFilter.period : '');

    }

    return fillMissingMonthDates(data, this.props.statsFilter.period ? this.props.statsFilter.period : '');
  }
  prepareChartData() {
    var { secondaryChart } = this.state;

    var data = this.state.data;


    if (_.includes(['day', 'month'], this.props.fillMissingValues)) {
      data = this.fillDataWithMissingDates(data);
      secondaryChart = this.fillDataWithMissingDates(secondaryChart);
    }

    if (!data) {
      return [];
    }
    if (!secondaryChart || _.isEmpty(secondaryChart)) {
      return data;
    }
    return data.map((d: any) => {
      const secondaryChartObj = _.find(secondaryChart, ['id', d.id]);
      return {
        ...d,
        secondaryValue: secondaryChartObj ? secondaryChartObj.value : (d.value !== null ? 0 : null)
      }
    });
  }
  render() {
    const { data } = this.state;
    const chartData = this.prepareChartData();
    const { valueName, secondaryValueName, hideDot, disableAnimation, height } = this.props

    const fill = (this.props.gradientId == 'secondary') ? '#009BC2' : '#002e67';
    const stroke = (this.props.gradientId == 'secondary') ? '#009BC2' : '#002e67';
    const loader = <div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div>
    return (
      <div className="Charts_articleViews Charts_topLevelContainer">
        <div className="chart-header"><I18n t={this.props.title} n='Charts' /></div>
        <div className="chart-body">
          <LanguageContextConsumer>
            {languageContext => {
              if (!languageContext) {
                return null;
              }
              const language = languageContext.language;
              const tr = Translate(language, 'Metrics');
              return (
                <ResponsiveContainer height={height ? height : 280}>
                  {data === undefined ?
                    loader :
                    <LineChart data={chartData}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                      <CartesianGrid vertical={false} />
                      <XAxis dataKey="id" tickFormatter={(t) => this.xTickFormatter(t)} padding={{ left: 10, right: 0 }} />
                      <YAxis tickFormatter={(t) => this.yTickFormatter(t)} allowDecimals={false} />
                      <Tooltip labelFormatter={this.xTickFormatter.bind(this)} formatter={this.tooltipFormatter.bind(this)} />
                      <Legend />
                      {this.props.avgValue ?
                        <ReferenceLine y={this.props.avgValue} stroke={'red'} label={tr('Avg value')} strokeDasharray="3 3" />
                        : ''
                      }
                      <Line name={tr(valueName || '')} dataKey="value" stroke={stroke} strokeWidth={2} type={'monotone'} dot={hideDot ? false : { strokeWidth: 2, r: 4 }} isAnimationActive={disableAnimation ? false : true} animationDuration={500} />
                      {this.props.secondaryChart ?
                        <Line name={tr(secondaryValueName || '')} dataKey="secondaryValue" stroke={(this.props.gradientId != 'secondary') ? '#009BC2' : '#002e67'} strokeWidth={2} type={'monotone'} dot={hideDot ? false : { strokeWidth: 2, r: 4 }} isAnimationActive={disableAnimation ? false : true} animationDuration={500} />
                        : ''
                      }
                    </LineChart>
                  }
                </ResponsiveContainer>
              )
            }}
          </LanguageContextConsumer>
        </div>
      </div>
    );
  }
}
