import React, { useMemo } from 'react'
import _compact from 'lodash/compact';

import cn from 'classnames'

import { media, MediaHelper, UrlProxyMapper } from '@imgzine/imgzine-core-frontend';

import styles from './DetailMediaInlineImage.module.scss'
import { ArticleDetailStyles } from './ArticlePreview';

interface Props {
  mediaObj: media.Image
  urlProxyMapper: UrlProxyMapper,
  // openImageViewer: detailView.OpenImageViewerFunc,
  classes: ArticleDetailStyles,
}

const LEGACY_CLASSES = {
  RIGHT: 'right',
  LEFT: 'left',
  CENTER: 'center'
}

const O4C_IMAGE_CLASS = 'o4c-image'

const SIZE_CLASSES = {
  FULL: 'size-full',
  LARGE: 'size-large', // default
  MEDIUM: 'size-medium',
  SMALL: 'size-small',
  ORIGINAL: 'size-original',
}

const ALIGNMENT_CLASSES = {
  FLOAT: 'align-float-left',
  BLOCK: 'align-block-center', // default
}

function getImageContainerClasses(mediaObjectClasses: string[], imageWidth: number | undefined): string[] {

  if (mediaObjectClasses.includes(O4C_IMAGE_CLASS)) {
    const alignmentClass = mediaObjectClasses.includes(ALIGNMENT_CLASSES.FLOAT)
      ? styles.floatLeft
      : styles.block  // default is block

    if (mediaObjectClasses.includes(SIZE_CLASSES.FULL)) {
      return [styles.full, alignmentClass]
    } else if (mediaObjectClasses.includes(SIZE_CLASSES.LARGE)) {
      return [styles.large, alignmentClass]
    } else if (mediaObjectClasses.includes(SIZE_CLASSES.MEDIUM)) {
      return [styles.medium, alignmentClass]
    } else if (mediaObjectClasses.includes(SIZE_CLASSES.SMALL)) {
      return [styles.small, alignmentClass]
    } else if (mediaObjectClasses.includes(SIZE_CLASSES.ORIGINAL)) {
      return [styles.original, alignmentClass]
    } else {
      return [styles.large, alignmentClass] // default is large
    }
  } else {
    //legacy
    if (mediaObjectClasses.includes(LEGACY_CLASSES.RIGHT)) {
      return [styles.legacy, styles.floatRight]
    } else if (mediaObjectClasses.includes(LEGACY_CLASSES.LEFT)) {
      return [styles.legacy, styles.floatLeft]
    } else if (mediaObjectClasses.includes(LEGACY_CLASSES.CENTER)) {
      return [styles.legacy, styles.block]
    } else if (imageWidth !== undefined && imageWidth <= 320) {
      return [styles.legacy, styles.floatLeft]
    } else {
      return [styles.legacy, styles.block]
    }
  }
}


export default function DetailMediaInlineImage(props: Props) {
  const { mediaObj, urlProxyMapper, classes } = props
  const imageLink: MediaHelper.ImageLink | undefined = useMemo(
    () => MediaHelper.extractBestQualityImageLink(mediaObj),
    [mediaObj]
  );
  const mediaObjectClasses = useMemo(
    () => _compact(mediaObj.class?.split(/\s/) || []),
    [mediaObj.class]
  )

  if (imageLink === undefined) {
    return null
  }

  const imageWidth = MediaHelper.extractInlineImageWidth(mediaObj) //very legacy
    || imageLink.w

  const alt = mediaObj.meta?.alt || undefined
  const caption = mediaObj.meta?.caption || undefined

  const containerClasses = cn(
    styles.image,
    getImageContainerClasses(mediaObjectClasses, imageWidth)
  )

  const imgProps = {
    src: urlProxyMapper(imageLink.url, { w: imageWidth }),
    alt,
    // onClick: mediaObj.hasLink ? undefined : () => { openImageViewer(mediaObj) }
  }

  
  if (caption && caption.length) {
    // replicating tinymce's html output
    return <figure className={containerClasses}>
      <img {...imgProps} />
      <figcaption className={cn(styles.caption, classes.caption)}>{caption}</figcaption>
    </figure>
  } else {
    return <img {...imgProps} className={containerClasses} />
  }
}