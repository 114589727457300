import React from 'react';
import { Translations } from "../../translations";
import _ from 'lodash';
import classnames from 'classnames';
import "react-datepicker3/dist/react-datepicker.css";
import { CustomPeriodPicker, CustomPeriodPickerMode } from './CustomPeriodPicker';

interface Props {
    header?: string
    periodFilters: string[]
    activeFilter: string
    activePeriod?: string
    changePeriod: (filter: string) => void
    customPeriodPickerMode?: CustomPeriodPickerMode
    todayEnabled?: boolean
}

export function PeriodGroup(props: Props) {
    const { header, periodFilters, activeFilter, changePeriod, activePeriod, customPeriodPickerMode, todayEnabled } = props;
    const tr = Translations.Translate(Translations, 'FilterBar');
    const isActiveFilter = (f: string) => {
        return f == activeFilter;
    }
    const isCustomActive = (m: CustomPeriodPickerMode): boolean => {
        switch (m) {
            case 'daily':
                return isActiveFilter('customDay');
            case 'weekly':
                return isActiveFilter('customWeek');
            case 'monthly':
                return isActiveFilter('customMonth');
            case 'yearly':
                return isActiveFilter('customYear');
        }
    }
    return (
        <div className={'periodGroup'}>
            <div className={'mainSection'}>
                {header &&
                    <div className={'groupHeader'}>{tr(header)}</div>
                }
                <div className={'groupFilters'}>
                    {_.map(periodFilters, (p) => {
                        return (
                            <div key={p} className={classnames({ 'groupFilter': true, 'active': isActiveFilter(p) })} onClick={() => changePeriod(p)}>{tr(p)}</div>
                        )
                    })}
                </div>
            </div>
            {!customPeriodPickerMode ? null :
                <div className={classnames({ 'iconSection': true, 'active': isCustomActive(customPeriodPickerMode) })}>
                    <CustomPeriodPicker {...{ activePeriod, isActiveFilter, changePeriod, mode: customPeriodPickerMode, todayEnabled }} />
                </div>
            }
        </div>
    )
}