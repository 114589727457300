import _ from 'lodash';
import { Editor as TinyMCEEditor } from 'tinymce';

export enum IMAGE_SIZE {
  full = 'full',
  large = 'large',
  medium = 'medium',
  small = 'small',
  original = 'original',
}

export enum IMAGE_ALIGNMENT {
  left = 'left',
  center = 'center',
}

enum IMAGE_CLASS_PREFIX {
  edCms = 'o4c-image',
  size = 'size-',
  alignFloat = 'align-float-',
  alignBlock = 'align-block-',
}

export enum IMAGE_SIZE_CLASS {
  full = `${IMAGE_CLASS_PREFIX.size}${IMAGE_SIZE.full}`,
  large = `${IMAGE_CLASS_PREFIX.size}${IMAGE_SIZE.large}`,
  medium = `${IMAGE_CLASS_PREFIX.size}${IMAGE_SIZE.medium}`,
  small = `${IMAGE_CLASS_PREFIX.size}${IMAGE_SIZE.small}`,
  original = `${IMAGE_CLASS_PREFIX.size}${IMAGE_SIZE.original}`,
}

enum IMAGE_ALIGNMENT_CLASS {
  left = `${IMAGE_CLASS_PREFIX.alignFloat}${IMAGE_ALIGNMENT.left}`,
  center = `${IMAGE_CLASS_PREFIX.alignBlock}${IMAGE_ALIGNMENT.center}`,
}


const DEFAULT_IMAGE_SIZE = IMAGE_SIZE.large;
const DEFAULT_IMAGE_ALIGNMENT = IMAGE_ALIGNMENT.center;

export const IMAGE_ED_CLASS = IMAGE_CLASS_PREFIX.edCms;
export const DEFAULT_IMAGE_SIZE_CLASS = IMAGE_SIZE_CLASS[DEFAULT_IMAGE_SIZE];
export const DEFAULT_IMAGE_ALIGNMENT_CLASS = IMAGE_ALIGNMENT_CLASS[DEFAULT_IMAGE_ALIGNMENT];

const FLOATABLE_IMAGE_SIZES: IMAGE_SIZE[] = [IMAGE_SIZE.medium , IMAGE_SIZE.small , IMAGE_SIZE.original];


export const getSelectedImageElement = (editor: TinyMCEEditor): Element | undefined=> {
  const elem = editor.selection?.getNode();
  if(elem.matches('img')){
    return elem;
  }
  if(elem.matches('figure')){ // case where img has caption
    const imgElem = elem.firstElementChild;
    if(!imgElem || !imgElem.matches('img')){
      return undefined;
    }
    return imgElem;
  }
  if(elem.matches('figcaption')){ // case that the caption has been clicked
    const imgElem = elem.previousElementSibling;
    if(!imgElem || !imgElem.matches('img')){
      return undefined;
    }
    return imgElem;
  }
}

const hasSelectedElementThisSizeClass = (editor: TinyMCEEditor, size: IMAGE_SIZE): boolean => {
  const elem = getSelectedImageElement(editor);
  if (!elem) {
    return false;
  }
  return elem.classList.contains(IMAGE_SIZE_CLASS[size]);
}

const decideCurrentImageSize = (editor: TinyMCEEditor): IMAGE_SIZE | undefined => {
  if (hasSelectedElementThisSizeClass(editor, IMAGE_SIZE.full)) {
    return IMAGE_SIZE.full;
  }
  if (hasSelectedElementThisSizeClass(editor, IMAGE_SIZE.large)) {
    return IMAGE_SIZE.large;
  }
  if (hasSelectedElementThisSizeClass(editor, IMAGE_SIZE.medium)) {
    return IMAGE_SIZE.medium;
  }
  if (hasSelectedElementThisSizeClass(editor, IMAGE_SIZE.small)) {
    return IMAGE_SIZE.small;
  }
  if (hasSelectedElementThisSizeClass(editor, IMAGE_SIZE.original)) {
    return IMAGE_SIZE.original;
  }
  return undefined;
}

export const selectedImageSizeOptionText = (editor: TinyMCEEditor): string => {
  const currentImageSize = decideCurrentImageSize(editor);
  return `${_.capitalize(currentImageSize || 'Select Image')} size`;
}

const isFloatableImageSize = (size: IMAGE_SIZE | undefined): boolean => {
  return size ? FLOATABLE_IMAGE_SIZES.includes(size) : false;
}

export const addImageSizeOption = (editor: TinyMCEEditor, size: IMAGE_SIZE) => {
  return {
    type: "togglemenuitem",
    active: hasSelectedElementThisSizeClass(editor, size),
    text: `${_.capitalize(size)} size`,
    onAction: () => {
      applySizeToImage(editor, size);
    }
  }
}

const applySizeToImage = (editor: TinyMCEEditor, size: IMAGE_SIZE) => {
  const elem = getSelectedImageElement(editor);

  if (elem) {
    const wasFloat = elem.classList.contains(IMAGE_ALIGNMENT_CLASS.left);

    const shouldKeepFloating = wasFloat && isFloatableImageSize(size);
    const shouldRemoveFloating = wasFloat && !isFloatableImageSize(size);

    elem.setAttribute('class', `${IMAGE_ED_CLASS} ${IMAGE_SIZE_CLASS[size]}`);

    if (shouldKeepFloating) {
      elem.classList.add(IMAGE_ALIGNMENT_CLASS.left);
    }

    if (shouldRemoveFloating) {
      editor.execCommand('JustifyCenter');
    }
    updateClassesOfParentFigure(editor);
  }
}

export const updateClassesOfParentFigure = (editor:TinyMCEEditor) => {
  const imgElem = getSelectedImageElement(editor);
  if(!imgElem){
    return;
  }
  const figureElem = imgElem.parentElement;
  if(!figureElem?.matches('figure')){
    return;
  }
  figureElem.setAttribute('class','image');
  const imgElementClasses = imgElem.getAttribute('class')?.split(' ');
  if(imgElementClasses){
    figureElem.classList.add(...imgElementClasses);
    figureElem.classList.add(imgElementClasses.includes(IMAGE_ALIGNMENT_CLASS.left)?'align-left':'align-center');
  }
  
}

export const alignImage = (editor:TinyMCEEditor,alignment:IMAGE_ALIGNMENT) => {
  const elem = getSelectedImageElement(editor);
  if(!elem){
    return;
  }

  if(alignment === IMAGE_ALIGNMENT.left){
    const currentSize = decideCurrentImageSize(editor);

    if (!isFloatableImageSize(currentSize)) {
      applySizeToImage(editor, IMAGE_SIZE.medium);
    }
    elem.classList.add(IMAGE_ALIGNMENT_CLASS.left);
  }
  else if(alignment === IMAGE_ALIGNMENT.center){
    elem.classList.remove(IMAGE_ALIGNMENT_CLASS.left);
  }
  updateClassesOfParentFigure(editor);
}

export const fullImageClassListWithSize = (size:IMAGE_SIZE): string =>{
  return `${IMAGE_ED_CLASS} ${IMAGE_SIZE_CLASS[size]}`
}

export const fullImageClassListWithSizeAndAlignment = (size:IMAGE_SIZE,alignment:IMAGE_ALIGNMENT): string =>{
  return `${IMAGE_ED_CLASS} ${IMAGE_SIZE_CLASS[size]} ${IMAGE_ALIGNMENT_CLASS[alignment]}`
}
