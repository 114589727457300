import React, { useState } from 'react';
import { Translations } from "../../translations";
import _ from 'lodash';
import classnames from 'classnames';
import Datepicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";
import moment from 'moment';
import $ from 'jquery';

export type CustomPeriodPickerMode = 'daily' | 'weekly' | 'monthly' | 'yearly';
interface Props {
    mode: CustomPeriodPickerMode,
    activePeriod?: string
    isActiveFilter: (filter: string) => boolean
    changePeriod: (filter: string) => void
    todayEnabled?: boolean
}

export function CustomPeriodPicker(props: Props) {
    const { activePeriod, isActiveFilter, changePeriod, mode, todayEnabled } = props;

    const modeToDateFormat = (m: CustomPeriodPickerMode) => {
        switch (m) {
            case 'daily':
                return "yyyy-MM-dd";
            case 'weekly':
                return "yyyy-'W'w";
            case 'monthly':
                return "yyyy-MM";
            case 'yearly':
                return "yyyy";
        }
    }
    const modeToMomentFormat = (m: CustomPeriodPickerMode) => {
        switch (m) {
            case 'daily':
                return "YYYY-MM-DD";
            case 'weekly':
                return "GGGG-[W]W";
            case 'monthly':
                return "YYYY-MM";
            case 'yearly':
                return "YYYY";
        }
    }
    const modeToCustomFilter = (m: CustomPeriodPickerMode) => {
        switch (m) {
            case 'daily':
                return 'customDay';
            case 'weekly':
                return 'customWeek';
            case 'monthly':
                return 'customMonth';
            case 'yearly':
                return 'customYear';
        }
    }
    const modeToMaxDate = (m: CustomPeriodPickerMode) => {
        switch (m) {
            case 'daily':
                return moment().subtract(todayEnabled ? 0 : 1, 'day').toDate();
            case 'weekly':
                return moment().subtract(1, 'day').toDate();
            case 'monthly':
                return moment().subtract(1, 'day').toDate();
            case 'yearly':
                return moment().subtract(1, 'day').toDate();
        }
    }
    const modeToSelected = (m: CustomPeriodPickerMode) => {
        return isActiveFilter(modeToCustomFilter(m)) ? (activePeriod ? moment(activePeriod, modeToMomentFormat(m)).toDate() : null) : null;
    }
    const handleCalendarOpen = () => {
        // make week number selectable
        $(`.react-datepicker-popper.${mode}`).find('.react-datepicker__week-number').on('click', (e: any) => {
            const el = e.target;
            $(el).parents('.react-datepicker__week').find('.react-datepicker__day:nth-child(3)').trigger('click');
        });
        // add selected class to selected week
        $(`.react-datepicker-popper.${mode}`).find('.react-datepicker__day--range-start').parents('.react-datepicker__week').addClass('selected');

        // add opened to iconSecton
        $(`.customPeriodPickerToggle.${mode}`).parents('.iconSection').addClass('opened');

        // add close on toggle
        $(`.customPeriodPickerToggle.${mode}`)
            .removeClass('react-datepicker-ignore-onclickoutside')
            .on('click.opened', (e) => {
                const el = e.target;
                $(el).off('click.opened');
                e.preventDefault();
                e.stopPropagation();
                return false;

            });
    }
    const handleCalendarClose = () => {
        // remove opened from iconSecton
        $(`.customPeriodPickerToggle.${mode}`).parents('.iconSection').removeClass('opened');
    }
    return (
        <Datepicker
            useWeekdaysShort
            dateFormat={modeToDateFormat(mode)}
            minDate={moment('2010-01-01', 'YYYY-MM-DD').toDate()}
            maxDate={modeToMaxDate(mode)}
            selected={modeToSelected(mode)}
            isClearable={false}
            // customInput={<img src='/assets/icons/calendar_small.svg'/>}
            // customInput={<i className='fa fa-calendar'></i>}
            customInput={<div className={`customPeriodPickerToggle ${mode}`}><i className='fa fa-calendar'></i></div>}
            onChange={(d: Date, e: any) => {
                let dateStr = '';
                if (d) {
                    try {
                        const m = moment(d);
                        if (m.isValid()) {

                            dateStr = m.format(modeToMomentFormat(mode));
                            changePeriod(dateStr);

                        }
                    } catch (error) {
                        changePeriod('');
                    }
                } else {
                    changePeriod('');
                }
            }}
            onChangeRaw={(e) => {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                return false;

            }}
            popperClassName={mode}
            popperPlacement={'top'}
            popperModifiers={{
                offset: {
                    enabled: true,
                    offset: "-3px, -20px"
                },
                preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport"
                }
            }}
            startDate={(mode === 'weekly') && isActiveFilter('customWeek') ? (activePeriod ? moment(activePeriod, 'GGGG-[W]W').toDate() : null) : null}
            endDate={(mode === 'weekly') && isActiveFilter('customWeek') ? (activePeriod ? moment(activePeriod, 'GGGG-[W]W').add(6, 'days').toDate() : null) : null}
            showWeekNumbers={mode === 'weekly'}
            showMonthYearPicker={mode === 'monthly'}
            showYearPicker={mode === 'yearly'}
            shouldCloseOnSelect={true}
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
        // closeOnScroll={true}
        />
    )
}