import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { StatsFilter } from '../../../domain';
import { DashBarChart, DashLineChart } from '../../charts';
import * as Api from '../../api';
import * as _ from 'lodash';
import StatBox from './StatBox';
import GroupFilter from './GroupFilter';

import './css/TabbedChartsBox.min.css';

export interface Props {
  magazine: string,
  statsFilter: StatsFilter
}

export interface State {
  activeTab?: string,
  totalVisits?: number | string,
  uniqueVisitors?: number | string,
  avgVisitDuration?: number | string,
  // totalArticleViews?: number | string,
  avgArticlesPerUser?: number | string,
  totalArticleViewers?: number | string,
  usersGroupBy?: string,
  // visitsGroupBy?: string,
  viewsGroupBy?: string,
  timeGroupBy?: string,
}

export class TabbedChartsBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'uniqueUsers',
      usersGroupBy: this.decideGroupBy(props.statsFilter.period),
      // visitsGroupBy: 'day',
      viewsGroupBy: this.decideGroupBy(props.statsFilter.period),
      timeGroupBy: this.decideGroupBy(props.statsFilter.period),
    };
  }
  componentDidMount() {
    this.calcChartStats('uniqueVisitors');
    this.calcChartStats('totalVisits');
    this.calcChartStats('avgVisitDuration');
    this.calcChartStats('avgArticlesPerUser');
  }
  calcChartStats(chart: string) {
    Api.loadChartData(chart, this.props.statsFilter, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ [chart]: '?' });
      }
      this.setState({ [chart]: resp.data.data ? resp.data.data : '-' });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  decideGroupBy(period?: string): string {
    return this.isPeriodForMonthlyGroup(period) ? 'month' : 'day';
  }
  useMonthInsteadOfDay(period?: string): boolean {
    return this.isPeriodForMonthlyGroup(period) ? true : false;
  }

  isDailyPeriod(period?: string): boolean {
    if (!period) {
      return false;
    }
    return (/^\d{4}-\d{2}-\d{2}$/.test(period)) || period === 'today' //daily
  }
  isYearlyPeriod(period?: string): boolean {
    if (!period) {
      return false;
    }
    return (/^\d{4}$/.test(period)) //yearly
  }
  isPeriodForMonthlyGroup(period?: string): boolean {
    return this.isYearlyPeriod(period) || period == '*';
  }

  toggle(tabId: string) {
    this.setState({ activeTab: tabId })
  }
  uniqueUsersGroupFilterHandler(groupBy: string) {
    this.setState({ usersGroupBy: groupBy });
  }
  drawUniqueUsersChart() {
    const { magazine, statsFilter } = this.props;
    const usersGroupBy = this.isDailyPeriod(statsFilter.period) ? 'hour' : (this.state.usersGroupBy ? this.state.usersGroupBy : this.decideGroupBy(statsFilter.period));
    switch (usersGroupBy) {
      case 'hour':
        return <DashBarChart key={'visitorsPerHour'} valueName={'unique_users'} chart={'visitorsPerHour'} secondaryValueName={'visits'} secondaryChart={'visitsPerHour'} title={'unique_users_and_visits_per_hour'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={usersGroupBy} />
      case 'weekday':
        return <DashBarChart key={'visitorsPerWeekday'} valueName={'unique_users'} chart={'visitorsPerWeekday'} secondaryValueName={'visits'} secondaryChart={'visitsPerWeekday'} title={'unique_users_and_visits_per_weekday'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={usersGroupBy} />
      case 'day':
        return <DashLineChart key={'visitorsPerDay'} valueName={'unique_users'} chart={'visitorsPerDay'} secondaryValueName={'visits'} secondaryChart={'visitsPerDay'} title={'unique_users_and_visits_per_day'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={usersGroupBy} disableAnimation={true} fillMissingValues={'day'} />
      case 'month':
        return <DashLineChart key={'visitorsPerMonth'} valueName={'unique_users'} chart={'visitorsPerMonth'} secondaryValueName={'visits'} secondaryChart={'visitsPerMonth'} title={'unique_users_and_visits_per_month'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={usersGroupBy} disableAnimation={true} />
      default:
        return <DashLineChart key={'visitorsPerDay'} valueName={'unique_users'} chart={'visitorsPerDay'} secondaryValueName={'visits'} secondaryChart={'visitsPerDay'} title={'unique_users_and_visits_per_day'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={usersGroupBy} disableAnimation={true} />

    }
  }
  articlesPerUserGroupFilterHandler(groupBy: string) {
    this.setState({ viewsGroupBy: groupBy });
  }
  drawViewsChart() {
    const { magazine, statsFilter } = this.props;
    const viewsGroupBy = this.isDailyPeriod(statsFilter.period) ? 'hour' : (this.state.viewsGroupBy ? this.state.viewsGroupBy : this.decideGroupBy(statsFilter.period));

    switch (viewsGroupBy) {
      case 'hour':
        return <DashBarChart key={'avgArticlesPerUserPerHour'} valueName={'avg_articles_per_user'} chart={'avgArticlesPerUserPerHour'} title={'avg_articles_per_user_per_hour'} yAxisType='avg' magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={viewsGroupBy} />
      case 'weekday':
        return <DashBarChart key={'avgArticlesPerUserPerWeekday'} valueName={'avg_articles_per_user'} chart={'avgArticlesPerUserPerWeekday'} title={'avg_articles_per_user_per_weekday'} yAxisType='avg' magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={viewsGroupBy} />
      case 'day':
        return <DashLineChart key={'avgArticlesPerUserPerDay'} valueName={'avg_articles_per_user'} chart={'avgArticlesPerUserPerDay'} title={'avg_articles_per_user_per_day'} yAxisType='avg' magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={viewsGroupBy} fillMissingValues={'day'} />
      case 'month':
        return <DashLineChart key={'avgArticlesPerUserPerMonth'} valueName={'avg_articles_per_user'} chart={'avgArticlesPerUserPerMonth'} title={'avg_articles_per_user_per_month'} yAxisType='avg' magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={viewsGroupBy} />
      default:
        return <DashLineChart key={'avgArticlesPerUserPerDay'} valueName={'avg_articles_per_user'} chart={'avgArticlesPerUserPerDay'} title={'avg_articles_per_user_per_day'} yAxisType='avg' magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={viewsGroupBy} />

    }
  }
  timeGroupFilterHandler(groupBy: string) {
    this.setState({ timeGroupBy: groupBy });
  }
  toTime(secs: number): string {
    if (!secs) {
      return '-';
    }
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + (seconds ? (seconds + 's') : '');
  }
  drawTimeChart() {
    const { magazine, statsFilter } = this.props;
    const timeGroupBy = this.isDailyPeriod(statsFilter.period) ? 'hour' : (this.state.timeGroupBy ? this.state.timeGroupBy : this.decideGroupBy(statsFilter.period));

    switch (timeGroupBy) {
      case 'hour':
        // return  <DashLineChart key={'screenTimePerHour'} chart={'screenTimePerHour'} extraCharts={['readTimePerHour']} title={'Screen and reading time per hour of day'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={this.state.timeGroupBy} yAxisType='time' />
        return <DashBarChart key={'screenTimePerHour'} valueName={'avg_visit_duration'} chart={'screenTimePerHour'} secondaryValueName={'avg_time_reading_an_article'} secondaryChart={'readTimePerHour'} title={'avg_visit_duration_time_reading_per_hour'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={timeGroupBy} yAxisType='time' />
      case 'weekday':
        // return  <DashLineChart key={'screenTimePerWeekday'} chart={'screenTimePerWeekday'} extraCharts={['readTimePerWeekday']} title={'Screen and reading time per day of week'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={this.state.timeGroupBy} yAxisType='time'/>
        return <DashBarChart key={'screenTimePerWeekday'} valueName={'avg_visit_duration'} chart={'screenTimePerWeekday'} secondaryValueName={'avg_time_reading_an_article'} secondaryChart={'readTimePerWeekday'} title={'avg_visit_duration_time_reading_per_weekday'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={timeGroupBy} yAxisType='time' />
      case 'day':
        return <DashLineChart key={'screenTimePerDay'} valueName={'avg_visit_duration'} chart={'screenTimePerDay'} secondaryValueName={'avg_time_reading_an_article'} secondaryChart={'readTimePerDay'} title={'avg_visit_duration_time_reading_per_day'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={timeGroupBy} yAxisType='time' avgValue={_.toNumber(this.state.avgVisitDuration)} fillMissingValues={'day'} />
      case 'month':
        return <DashLineChart key={'screenTimePerMonth'} valueName={'avg_visit_duration'} chart={'screenTimePerMonth'} secondaryValueName={'avg_time_reading_an_article'} secondaryChart={'readTimePerMonth'} title={'avg_visit_duration_time_reading_per_month'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={timeGroupBy} yAxisType='time' avgValue={_.toNumber(this.state.avgVisitDuration)} />
      default:
        return <DashLineChart key={'screenTimePerDay'} valueName={'avg_visit_duration'} chart={'screenTimePerDay'} secondaryValueName={'avg_time_reading_an_article'} title={'avg_visit_duration_time_reading_per_day'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy={timeGroupBy} yAxisType='time' />

    }
  }
  calcVisitsPerUser(): number | string | undefined {
    const { totalVisits, uniqueVisitors } = this.state;
    if (totalVisits === undefined || uniqueVisitors === undefined) {
      return undefined;
    }
    if (!_.isNumber(uniqueVisitors) || !_.isNumber(totalVisits) || uniqueVisitors === 0) {
      return '-';
    }
    return (totalVisits / uniqueVisitors).toFixed(1);
  }
  // calcArticleViewsPerUser():number|string|undefined{
  //   const {totalArticleViews,uniqueVisitors} = this.state;
  //   if(totalArticleViews === undefined || totalArticleViews === undefined){
  //     return undefined;
  //   }
  //   if(!_.isNumber(uniqueVisitors) || !_.isNumber(totalArticleViews) || uniqueVisitors === 0){
  //     return '-';
  //   }
  //   return (totalArticleViews/uniqueVisitors).toFixed(1);
  // }
  calcAvgArticlesPerUser(): number | string | undefined {
    const { avgArticlesPerUser } = this.state;
    if (avgArticlesPerUser === undefined) {
      return undefined;
    }
    if (!_.isNumber(avgArticlesPerUser)) {
      return '-';
    }
    return (avgArticlesPerUser).toFixed(1);
  }
  render() {
    const { magazine, statsFilter } = this.props;
    const { totalVisits, uniqueVisitors, avgVisitDuration, usersGroupBy, viewsGroupBy, timeGroupBy } = this.state;


    return (
      <div id="TabbedChartsBox" className="boxContainer">
        <Row style={{ height: '100%' }}>
          <Col md={2}>
            <div className={this.state.activeTab == 'uniqueUsers' ? 'tabBox active' : 'tabBox'} onClick={(e) => this.toggle('uniqueUsers')}><StatBox text={'unique_users'} value={!_.isArray(uniqueVisitors) ? uniqueVisitors : '-'} icon={''} /></div>
            <div className={this.state.activeTab == 'visits' ? 'tabBox active' : 'tabBox'} onClick={(e) => this.toggle('visits')}><StatBox text={'avg_visits_per_user'} value={this.calcVisitsPerUser()} icon={''} /></div>
            <div className={this.state.activeTab == 'articlesPerUser' ? 'tabBox active' : 'tabBox'} onClick={(e) => this.toggle('articlesPerUser')}><StatBox text={'avg_articles_per_user'} value={this.calcAvgArticlesPerUser()} icon={''} /></div>
            <div className={this.state.activeTab == 'readingScreenTime' ? 'tabBox active' : 'tabBox'} onClick={(e) => this.toggle('readingScreenTime')}><StatBox text={'avg_visit_duration'} value={avgVisitDuration ? this.toTime(_.toNumber(avgVisitDuration)) : avgVisitDuration} icon={''} /></div>
          </Col>
          <Col md={10}>
            <div className={this.state.activeTab == 'uniqueUsers' ? 'chartBox active' : 'chartBox'} onClick={(e) => this.toggle('uniqueUsers')}>
              <GroupFilter isDaily={this.isDailyPeriod(statsFilter.period)} key={'usersGroupBy-' + this.decideGroupBy(statsFilter.period)} groupBy={usersGroupBy ? usersGroupBy : this.decideGroupBy(statsFilter.period)} groupFilterHandler={this.uniqueUsersGroupFilterHandler.bind(this)} isSinceAppStartEnabled={this.useMonthInsteadOfDay(statsFilter.period)} />
              {this.drawUniqueUsersChart()}
            </div>
            <div className={this.state.activeTab == 'visits' ? 'chartBox active' : 'chartBox'} onClick={(e) => this.toggle('visits')}>
              <DashBarChart key={'visitsPerPersonDistribution'} valueName={_.includes(['*', `last30days`], statsFilter.period) ? `avg_users_per_${this.decideGroupBy(statsFilter.period)}` : 'users'} yAxisType={(_.includes(['*', 'last30days'], statsFilter.period) ? 'avg' : undefined)} chart={'visitsPerPersonDistribution'} title={'frequency_of_visits_per_user'} magazine={parseInt(magazine)} statsFilter={statsFilter} groupBy='visits' />
            </div>
            <div className={this.state.activeTab == 'articlesPerUser' ? 'chartBox active' : 'chartBox'} onClick={(e) => this.toggle('articlesPerUser')}>
              <GroupFilter isDaily={this.isDailyPeriod(statsFilter.period)} groupBy={viewsGroupBy ? viewsGroupBy : this.decideGroupBy(statsFilter.period)} groupFilterHandler={this.articlesPerUserGroupFilterHandler.bind(this)} isSinceAppStartEnabled={this.useMonthInsteadOfDay(statsFilter.period)} />
              {this.drawViewsChart()}
            </div>
            <div className={this.state.activeTab == 'readingScreenTime' ? 'chartBox active' : 'chartBox'} onClick={(e) => this.toggle('readingScreenTime')}>
              <GroupFilter isDaily={this.isDailyPeriod(statsFilter.period)} groupBy={timeGroupBy ? timeGroupBy : this.decideGroupBy(statsFilter.period)} groupFilterHandler={this.timeGroupFilterHandler.bind(this)} isSinceAppStartEnabled={this.useMonthInsteadOfDay(statsFilter.period)} />
              {this.drawTimeChart()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TabbedChartsBox;
