import { useMemo } from 'react';
import _ from 'lodash';
import { Translations } from '../../translations';
import { BasicBucket, Bucket } from './domain';
import { Profile } from '../../../domain';
import { AccessRightsHelper } from '../../utils';


const enhanceFeedBuckets = (feedBuckets: BasicBucket[]): Bucket[] => {
  return _.map(feedBuckets, (fb) => {
    return {
      ...fb,
      useOnlyPart: 'protected',
      group: 'feed'
    }
  })
}

const extractFeedIdFromFeedBucket = (magazine: number, feedBucket: BasicBucket): string => {
  return feedBucket.key.split(`-feed-`)[1];
}


const filterBucketsBasedOnAccessRights = (magazine: number, profile: Profile, theBuckets: Bucket[]): Bucket[] => {
  const isAdmin = AccessRightsHelper.getUserRole(profile) == 'admin';

  if (isAdmin) {
    return [...theBuckets];
  }

  return _.filter(theBuckets, (b) => ((b.group == 'default' && b.nonAdmin == true) || (b.group == 'feed' && _.includes(profile.feedsIds, extractFeedIdFromFeedBucket(magazine, b)))));
}

function initDefaultMagazineBuckets(magazine: number, vimeoIntegration: boolean, narrowcasting: boolean): Bucket[] {
  const tr = Translations.Translate(Translations, 'MediaManagerSelector');

  const defaultMagazineBuckets: Bucket[] = [
    { key: `${magazine}`, label: tr(`defaultBucket`), group: 'default' },
    { key: `${magazine}_social`, label: tr(`defaultSocialBucket`), useOnlyPart: 'protected', group: 'default' },
    // { key: `${magazine}_nc`, label: tr(`defaultNcBucket`), useOnlyPart: 'protected', group: 'default' },
    // { key: `${magazine}_webapp`, label: tr(`defaultWebappBucket`), useOnlyPart: 'public', group: 'default' },
    { key: `${magazine}_cms`, label: tr(`defaultCmsBucket`), useOnlyPart: 'protected', group: 'default', nonAdmin: true },
  ];

  if (narrowcasting) {
    defaultMagazineBuckets.push({ key: `${magazine}_nc`, label: tr(`defaultNcBucket`), useOnlyPart: 'protected', group: 'default' });
  }

  if (vimeoIntegration) {

    defaultMagazineBuckets.push({ key: `vimeo`, label: tr(`defaultVimeoBucket`), useOnlyPart: 'protected', group: 'default', nonAdmin: true });
  }
  return defaultMagazineBuckets
}

export default function useMagazineBuckets(magazine: number, profile: Profile, magazineFeedBuckets: BasicBucket[] | undefined) {
  return useMemo(
    () => {
      const buckets = [
        ...initDefaultMagazineBuckets(magazine, profile.edConfig?.vimeoIntegration === true, profile.edConfig?.narrowcasting === true),
        ...magazineFeedBuckets ? enhanceFeedBuckets(magazineFeedBuckets) : []
      ]
      return filterBucketsBasedOnAccessRights(magazine, profile, buckets)
    },
    [magazine, magazineFeedBuckets, profile]
  )
}
