import React, { useState, useEffect, useMemo } from 'react';
import styles from './CmsVariationDetails.module.scss';
import { GenericModal } from '../../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../../components/GenericModal/GenericModalHeader';
import { Alert, Badge, Button, Input, Label } from 'reactstrap';
import { Controller, UseFormGetValues, useController, useForm } from 'react-hook-form';
import { CmsItemFormData, CmsFeedInfo, calcConfirmation, deProxifyContent, proxifyContent } from '../CmsItemDetails';
import { EdErrorHandler, LoadingButton, SwalDelete, SwalSuccess } from '../../../../../../widgets';
import { MediaSelector, TippyReact } from '../../../../../../components';
import { CmsItemDetailed, CmsItemDetailedData, CmsItemDetailedWithOtherVariations, CmsMediaObject, CmsStage, VariationLanguage } from '../../CmsDomain';
import { FullPageModalContent, FullPageModalContentPart, FullPageModalSettings, FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../../../../../components/FullPageModal';
import { CmsContentEditor } from '../components/CmsContentEditor';
import { CmsFeaturedImage } from '../components/CmsFeaturedImage';
import { CmsConfirmationSection } from '../components/CmsConfirmationSection';
import { Profile } from '../../../../../../../domain';
import _ from 'lodash';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { API } from '../../../../../../apis';
import { CmsPreviewer } from '../../CmsPreviewer/CmsPreviewer';
import { getCmsBuckets, useMagazineFeedBuckets } from '../../../../../../components/MediaManagerSelector/MediaManagerSelectorHelper';
import useMagazineBuckets from '../../../../../../components/MediaManagerSelector/useMagazineBuckets';
import { MediaHelper } from '../../../../../../utils';
import { getImageSize } from 'react-image-size';
import { CmsNavigationPromptModal } from '../components/CmsNavigationPrompt';

export type CmsVariationFormData = Pick<CmsItemFormData,
  "title" |
  "intro" |
  "featuredImage" |
  "confirmation" |
  "content"
>

interface Props {
  magazine: number,
  profile: Profile,
  itemId: string,
  activeFeed: CmsFeedInfo,
  cmsStage: CmsStage,
  variationLanguage: VariationLanguage,
  isNew: boolean,
  agendaMode?: boolean,
  getParentValues: UseFormGetValues<CmsItemFormData>
  onClose: () => void
}

export const CmsVariationDetails = ({ magazine, profile, itemId, activeFeed, cmsStage, isNew, variationLanguage, agendaMode, getParentValues, onClose }: Props) => {

  const [parentTitle, parentIntro, parentFeaturedImage, parentConfirmation, parentContent, parentTargetUid] = getParentValues(['title', 'intro', 'featuredImage', 'confirmation', 'content', 'target_uid']);

  const magazineFeedBucketsQ = useMagazineFeedBuckets(magazine);
  const allBuckets = useMagazineBuckets(magazine, profile, magazineFeedBucketsQ.data);

  const [mediaSelectorMode, setMediaSelectorMode] = useState<'featured' | undefined>(undefined);
  const [showNavigationPrompt, setShowNavigationPrompt] = useState<boolean>(false);

  const cmsBuckets = useMemo(() => {
    return getCmsBuckets(magazine, activeFeed.id, allBuckets);
  }, [magazine, activeFeed.id, allBuckets]);


  const queryClient = useQueryClient();

  const [previewItem, setPreviewItem] = useState<CmsItemDetailedWithOtherVariations>();
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);

  const isDraft = (cmsStage === 'drafts');

  const cmsItemVariationQuery = useQuery({
    queryKey: ['cmsItemVariation', itemId, variationLanguage.key],
    enabled: !isNew,
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsItem(magazine, activeFeed.id, cmsStage, itemId, `lang:${variationLanguage.key}`);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS item ${variationLanguage.name} variation`);
      }
    }
  });

  const { control, handleSubmit, clearErrors, setError, reset, formState: { errors, isDirty } } = useForm<CmsVariationFormData>({
    defaultValues: isNew ? {
      title: parentTitle,
      intro: parentIntro,
      featuredImage: parentFeaturedImage,
      confirmation: parentConfirmation,
      content: parentContent
    } : {
      title: '',
      intro: '',
      featuredImage: {
        url: '',
        alt: ''
      },
      confirmation: undefined,
      content: '',
    }
  });

  const { field: featuredImageField, fieldState: featuredImageFieldState } = useController({ name: 'featuredImage', control });
  // const { field: attachmentsField, fieldState: attachmentsFieldState } = useController({ name: 'attachments', control });

  useEffect(() => {
    if (cmsItemVariationQuery.data && !isNew) {
      reset(itemDetailedDataToFormData(magazine, cmsItemVariationQuery.data.data, activeFeed))
    }
  }, [cmsItemVariationQuery.data, reset, itemId, magazine, activeFeed, isNew]);

  useEffect(() => {
    const onBeforeUnload = (e: Event) => {
      if (isDirty) {
        e.preventDefault();
      }

      return isDirty;
    }

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);

    }
  }, [isDirty])


  const loading = cmsItemVariationQuery.isFetching;


  const titleHasErrors = errors.title ? true : false;
  const hasErrors = titleHasErrors;

  const cmsItemVariationSaveMutation = useMutation({
    mutationKey: ['ncItemVariationUpdate'],
    mutationFn: (d: CmsItemDetailed) => API.cms.saveCmsItem(magazine, activeFeed.id, cmsStage, d, false),
    onError: (error, s) => {
      EdErrorHandler(error, `saving CMS item variation`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS item variation <${d.data.title}> has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['cmsItemVariationsLangs', itemId] })
      onClose();
    }
  })

  const cmsDraftVariationDeleteMutation = useMutation({
    mutationKey: ['cmsDraftVariationDelete', itemId],
    mutationFn: (draftId: string) => API.cms.deleteCmsDraft(magazine, activeFeed.id, draftId, `lang:${variationLanguage.key}`),
    onError: (error, m) => {
      EdErrorHandler(error, `deleting CMS draft variation`);
    },
    onSuccess: (data, m) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `${variationLanguage.name} variation has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      onClose();
      queryClient.invalidateQueries({ queryKey: ['cmsItemVariationsLangs', itemId] });
    }
  });


  const onCloseMediaSelector = () => {
    setMediaSelectorMode(undefined)
  }

  const selectMediaHandler = (mediaUrl: string[], file?: any[]) => {
    if (_.isEmpty(mediaUrl)) {
      return;
    }

    if (mediaSelectorMode === 'featured') {
      featuredImageField.onChange({ ...featuredImageField.value, url: mediaUrl[0] })
      onCloseMediaSelector();
      return;
    }
    // if (mediaSelectorConfig.target === 'attachments') {
    //   attachmentsField.onChange([...attachmentsField.value, ...convertSelectedMediaToAttachments(mediaUrl, file)])
    //   onCloseMediaSelector();
    //   return;
    // }

  }


  const onPreview = handleSubmit(async (data) => {
    const itemDetailed: CmsItemDetailed = {
      feed: activeFeed.id,
      external_id: decodeURIComponent(itemId),
      variation: `lang:${variationLanguage.key}`,
      kind: agendaMode ? 'event' : 'article',
      data: await formDataToItemDetailedData(magazine, data, getParentValues(), agendaMode),
      target_uid: activeFeed.isPersonalized ? parentTargetUid : undefined
    };
    try {
      setPreviewLoading(true);
      const { data: itemToBePreviewed } = await API.cms.previewCmsItem(magazine, activeFeed.id, cmsStage, itemDetailed);
      setPreviewItem(itemToBePreviewed);
    } catch (error) {
      EdErrorHandler(error, `previewing this variation`);
    } finally {
      setPreviewLoading(false);
    }
  });

  const onSave = handleSubmit(async (data) => {
    const itemToBeSaved: CmsItemDetailed = {
      feed: activeFeed.id,
      external_id: decodeURIComponent(itemId),
      variation: `lang:${variationLanguage.key}`,
      kind: agendaMode ? 'event' : 'article',
      data: await formDataToItemDetailedData(magazine, data, getParentValues(), agendaMode),
      target_uid: activeFeed.isPersonalized ? parentTargetUid : undefined
    };
    cmsItemVariationSaveMutation.mutate(itemToBeSaved);
    reset(undefined, { keepValues: true });

  });

  const onDelete = async () => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete this ${variationLanguage.name} draft variation.`
    });
    if (!confirm) {
      return;
    }

    cmsDraftVariationDeleteMutation.mutate(itemId);
    reset(undefined, { keepValues: true });
  }

  const onCloseClick = () => {
    if (isDirty) {
      setShowNavigationPrompt(true);
    } else {
      onClose();
    }
  }

  return (
    <>
      {showNavigationPrompt &&
        <CmsNavigationPromptModal
          onConfirm={() => { setShowNavigationPrompt(false); onClose(); }}
          onCancel={() => { setShowNavigationPrompt(false); }}
          saveUnsavedWork={onSave}
          isDraft={isDraft}
        />
      }
      <GenericModal className={styles.CmsVariationDetails} isOpen={true} size={'xxl'} fullHeight>
        <GenericModalHeader
          onClose={onCloseClick}
          title={`${variationLanguage.name} variation`}
        />
        {isNew &&
          <Alert color={'warning'}>This is a new variation. We ’ve copied the content of primary language for your convenience, but it will be only created after hitting <i>create</i>.</Alert>
        }
        {!loading &&
          <div className={styles.subheader}>
            <Controller
              name={'title'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Input {...field} invalid={titleHasErrors} className={styles.titleInput} placeholder={`Enter your title here...`} onChange={(e) => { field.onChange(e.target.value) }} maxLength={200}></Input>
                )
              }}
            />
            <LoadingButton color={'secondary'} loading={previewLoading} onClick={() => { onPreview() }} text={'preview'} />
            {isDraft && <Badge className={styles.draftBadge}>Draft</Badge>}
            <TippyReact config={{ disabled: !hasErrors }} content={titleHasErrors ? 'Title is required!' : 'There are validation errors in your variation!'}><div><LoadingButton color={'primary'} disabled={hasErrors} loading={cmsItemVariationSaveMutation.isLoading} onClick={() => { onSave() }} text={isNew ? 'create' : 'save'} /></div></TippyReact>
          </div>
        }

        <FullPageModalContent isLoading={loading}>
          <FullPageModalContentPart flex={3} cssStyle={{ display: 'flex', flexDirection: 'column' }} mainPart>
            <CmsContentEditor
              magazine={magazine}
              profile={profile}
              control={control}
              cmsBuckets={cmsBuckets}
            />
          </FullPageModalContentPart>
          <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '250px' }} withBackground>
            <FullPageModalSettings>
              <FullPageModalSettingsSection title={'summary'}>
                {!isNew && isDraft &&
                  <FullPageModalSettingsSectionRow>
                    <Button className={styles.deleteButton} outline color={'danger'} onClick={() => { onDelete() }}>delete variation</Button>
                  </FullPageModalSettingsSectionRow>
                }
                <FullPageModalSettingsSectionRow>
                  <CmsFeaturedImage
                    magazine={magazine}
                    control={control}
                    onOpenMediaSelector={() => {
                      setMediaSelectorMode('featured')
                    }}
                  />
                </FullPageModalSettingsSectionRow>
                <FullPageModalSettingsSectionRow>
                  <Label>Description</Label>
                  <Controller
                    name={'intro'}
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Input {...field} type={'textarea'} placeholder={`Description text that will be added to the intro.`} onChange={(e) => { field.onChange(e.target.value) }}></Input>
                      )
                    }}
                  />
                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>
              <FullPageModalSettingsSection title={'settings'} separator>
                <FullPageModalSettingsSectionRow>
                  <Controller
                    name={'confirmation'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <CmsConfirmationSection
                          magazine={magazine}
                          confirmation={field.value}
                          onChange={field.onChange}
                        />)
                    }}
                  />


                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>

            </FullPageModalSettings>
          </FullPageModalContentPart>
        </FullPageModalContent>
        {mediaSelectorMode &&
          <MediaSelector
            magazine={magazine}
            profile={profile}
            closeHandler={onCloseMediaSelector}
            selectHandler={selectMediaHandler}
            dedicatedPart={'protected'}
            availableBuckets={cmsBuckets}
            restrictedMediaTypes={['images']}
            multipleSelection={false}
          />}
        {previewItem &&
          <CmsPreviewer
            magazine={magazine}
            item={previewItem}
            onClose={() => setPreviewItem(undefined)}
          />
        }
        {/* </ModalBody> */}
      </GenericModal>
    </>
  )
}

const itemDetailedDataToFormData = (magazine: number, queryData: Partial<CmsItemDetailedData>, activeFeed: CmsFeedInfo): CmsVariationFormData => {
  const { title, cdate, author, content, tags, intro, mdate, link, media } = queryData;

  const featuredImageMedia = media && !_.isEmpty(media) ? _.find(media, (m) => m.featured == 1) : undefined;

  const featuredImage = {
    url: featuredImageMedia?.url || '',
    alt: featuredImageMedia?.meta?.alt || ''
  }

  return {
    title: title || '',
    intro: intro || '',
    content: content ? proxifyContent(magazine, content) : '',
    featuredImage: featuredImage,
    // attachments: _.filter(media, (m) => m.type == 'file'),
    confirmation: content ? calcConfirmation(content) : undefined

  }
}

const formDataToItemDetailedData = async (magazine: number, formData: CmsVariationFormData, parentData: CmsItemFormData, agendaMode?: boolean): Promise<CmsItemDetailedData> => {
  const { author, link, publicationDate, attachments, categories, tags, meta } = parentData;
  const { title, intro, content, featuredImage, confirmation } = formData;

  const featuredImageDimensions = !featuredImage?.url ? undefined : await getImageSize(MediaHelper.proxify(magazine, featuredImage.url));
  const featuredImageMediaObject: CmsMediaObject | undefined = !featuredImage?.url ? undefined :
    {
      type: "img",
      featured: 1,
      url: featuredImage.url,
      w: featuredImageDimensions?.width,
      h: featuredImageDimensions?.height,
      meta: {
        alt: featuredImage.alt
      }
    };

  const attachmentsMedia: CmsMediaObject[] = _.map(attachments, (a) => {
    return {
      link: a.link,
      filename: a.filename,
      extension: a.extension,
      mimeType: a.mimeType,
      type: 'file'
    }
  });

  const media: CmsMediaObject[] = _.compact([featuredImageMediaObject, ...attachmentsMedia]);


  return {
    title: title || '',
    intro: intro || '',
    author: author || '',
    link: link || '',
    content: content ? deProxifyContent(magazine, content) : '',
    cdate: publicationDate ? publicationDate.toISOString() : '',
    mdate: '',
    media: media,
    tags: [..._.map(categories, (c) => `_cat:${c}`), ...tags],
    confirmation,
    status: 1,
    meta: agendaMode ? meta : undefined,


  }
}